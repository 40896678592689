<template>
  <div class="notification">
    <v-tooltip bottom color="#311B92">
      <template v-slot:activator="{ on, attrs }">
        <div
          size="sm"
          v-on="on"
          v-bind="attrs"
          @click="menu = true"
          class="ml-3 pointer"
        >
          <div @click="markAllAsHoverdMethod()">
            <font-awesome-icon
              :icon="['far', 'bell']"
              class="fa-2x bell1 icon-notif"
            />
          </div>
          <div
            class="notification-circle"
            v-if="getCountNotification && getCountNotification > 0"
          >
            {{ getCountNotification }}
          </div>
        </div>
      </template>
      <span>Liste des notifications</span>
    </v-tooltip>
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      min-width="400px"
      max-width="400px"
      origin="top right"
      content-class="popover-menu-notification"
    >
      <v-card>
        <v-list>
          <v-list-item>
            <v-list-item-content class="title-list">
              <v-list-item-title>Notifications</v-list-item-title>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-divider class="line-separator-list"></v-divider>
        <LoadingComponent v-if="getNotificationsLoader" />
        <v-list v-else class="box-dropdown">
          <v-list-item
            v-for="(not, index) in getAllNotifications"
            :key="'notification' + not.id + index"
            @click="showdetailNotification(not)"
          >
            <v-list-item-content>
              <v-row>
                <v-col cols="2">
                  <div class="icon-type" :class="{ active: not.seen == false }">
                    <font-awesome-icon :icon="not.getIconName()" />
                  </div>
                </v-col>
                <v-col cols="8">
                  <div>
                    <span v-html="not.getMessageNotification()"> </span>
                  </div>
                </v-col>
                <v-col cols="2">
                  <div
                    class="icon-close"
                    @click.prevent.stop="handleDeleteNot(not)"
                  >
                    <div
                      class="chargement chargement-loading-icon-loader"
                      v-if="not.loaderDelet"
                    >
                      <div class="spinner-border" role="status">
                        <span class="sr-only">Loading...</span>
                      </div>
                    </div>
                    <font-awesome-icon :icon="'times'" v-else />
                  </div>
                </v-col>
              </v-row>
              <v-row class="row-list-export">
                <v-col cols="5">
                  <div class="time" :class="{ active: not.seen == false }">
                    <!-- il y a -->
                    {{ not.created_at }}
                  </div>
                </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list v-if="computedvoirPlus">
          <v-list-item>
            <v-list-item-content>
              <v-row>
                <v-col cols="2"> </v-col>
                <v-col cols="8">
                  <span
                    class="voir-plus"
                    @click.prevent.stop="hendelClickMoreNotif"
                  >
                    Voir plus
                  </span>
                </v-col>
                <v-col cols="2"> </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-list>
          <v-list-item
            v-if="getAllNotifications.length == 0 && !getNotificationsLoader"
          >
            <v-list-item-content>
              <v-row>
                <v-col cols="2"> </v-col>
                <v-col cols="8">
                  <span class="empty-export">
                    Aucune notification
                  </span>
                </v-col>
                <v-col cols="2"> </v-col>
              </v-row>
            </v-list-item-content>
          </v-list-item>
        </v-list>
        <v-card-actions>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-menu>
  </div>
</template>

<script>
import LoadingComponent from '@/views/component/LoadingComponent';
import { mapActions, mapGetters } from 'vuex';
export default {
  data() {
    return { page: 1, menu: false };
  },
  methods: {
    ...mapActions([
      'fetchAllNotifications',
      'updateNotification',
      'markAllAsHoverdAction',
      'fetchNewNotification',
      'deleteNot'
    ]),
    hideDropdown() {
      if (this.$refs['dropdown-notification']) {
        this.$refs['dropdown-notification'].hide(true);
      }
    },
    showdetailNotification(not) {
      this.hideDropdown();
      if (not.seen == false) {
        this.updateNotification(not);
      }
      not.showdetailsNotification();
    },
    markAllAsHoverdMethod() {
      if (this.getCountNotification > 0) {
        this.markAllAsHoverdAction({
          ids: this.getAllNotifications.map(i => i.id)
        });
      }
    },
    hendelClickMoreNotif() {
      this.page = this.page + 1;
      this.fetchNewNotification({ page: this.page });
    },
    handleFilter() {
      this.fetchAllNotifications({ page: this.page });
    },
    async handleDeleteNot(not) {
      not.loaderDelet = true;
      await this.deleteNot(not);
      not.loaderDelet = false;
    }
  },
  mounted() {
    this.handleFilter();
  },
  computed: {
    ...mapGetters([
      'getAllNotifications',
      'getCountNotification',
      'getNoutificationToShow',
      'getNotificationsLoader',
      'gettotalPageNotification'
    ]),
    computedvoirPlus() {
      if (this.gettotalPageNotification / 10 > this.page) {
        return true;
      } else {
        return false;
      }
    }
  },
  components: {
    LoadingComponent
  }
};
</script>

<style lang="scss">
.time {
  font-size: 80%;
  font-weight: 600;
  margin-left: 10px;
}
.active {
  color: #704ad1;
}
.icon-type {
  font-size: 20px;
  margin-left: 22px;
}
.icon-notif {
  font-size: 21px;
  margin-top: 9px;
}
.voir-plus {
  cursor: pointer;
  color: #704ad1;
  font-size: 12px;
  font-weight: 600;
  text-align: center;
  display: block;
  .title {
    color: #704ad1;
  }
}
.svg-inline--fa.fa-w-10 {
  height: 25px;
}
.notification {
  .notification-circle {
    width: 15px;
    height: 15px;
    border-radius: 50%;
    background-color: #704ad1;
    font-size: 11px;
    top: -28px;
    right: -10px;
    color: #fff;
    position: relative;
    z-index: 10;
    text-align: center;
  }
}
.box-dropdown {
  overflow-y: auto;
  max-height: 365px;
}
.box-dropdown::-webkit-scrollbar {
  width: 10px;
}

.box-dropdown::-webkit-scrollbar {
  width: 7px;
  height: 7px;
}
.box-dropdown::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #ffffff;
  border-radius: 10px;
}
.box-dropdown::-webkit-scrollbar-thumb {
  background: #704ad1;
  border-radius: 10px;
}
</style>
